import One from '../images/artwork/Memories/575415_10100270660965611_1752029589_n.jpg'
import Two from '../images/artwork/Memories/DSC04968.jpg'
import SchwaeBalloons from '../images/artwork/Memories/DSC05003.jpg'
import Four from '../images/artwork/Memories/DSC05926.jpg'
import PrettyConstruction from '../images/artwork/Memories/DSC06113.jpg'
import Leipzig from '../images/artwork/Memories/DSC06274.jpg'
import BerlinDance from '../images/artwork/Memories/DSC06472.jpg'
import Eight from '../images/artwork/Memories/DSC06592.jpg'
import Nine from '../images/artwork/Memories/DSC06748.jpg'
import StockbridgeTree from '../images/artwork/Memories/IMG_0356.jpg'
import Caterpillar from '../images/artwork/Memories/IMG_0530.jpg'
// import Fourteen from '../images/artwork/Memories/IMG_0596.jpg'
import Fifteen from '../images/artwork/Memories/IMG_0685.jpg'
import CyclePoster from '../images/artwork/Memories/IMG_0851.jpg'
import Mary from '../images/artwork/Memories/IMG_0937.jpg'
import LampShop from '../images/artwork/Memories/IMG_0946.jpg'
import Octopus from '../images/artwork/Memories/IMG_1016.jpg'
import Facebook from '../images/artwork/Memories/IMG_1138.jpg'
import MosquitoNet from '../images/artwork/Memories/IMG_1272.jpg'
import TwentyFour from '../images/artwork/Memories/IMG_1301.jpg'
import AurovilleGreenGate from '../images/artwork/Memories/IMG_1368.jpg'
import TwentySix from '../images/artwork/Memories/IMG_1699.jpg'
import TwentySeven from '../images/artwork/Memories/IMG_1971.jpg'
import TwentyEight from '../images/artwork/Memories/IMG_2382.jpg'
import Thirty from '../images/artwork/Memories/IMG_2732.jpg'
import ThirtyOne from '../images/artwork/Memories/IMG_2794.jpg'
import ThirtyTwo from '../images/artwork/Memories/IMG_3009_edited.jpg'
import ThirtyThree from '../images/artwork/Memories/IMG_3277.jpg'
import ThirtyFour from '../images/artwork/Memories/IMG_3366.jpg'
import ThirtyFive from '../images/artwork/Memories/IMG_3401.jpg'
import ThirtySix from '../images/artwork/Memories/IMG_5503.jpg'
// import ThirtySeven from '../images/artwork/Memories/IMG_5699.jpg'
import ThirtyEight from '../images/artwork/Memories/IMG_8955.jpg'
import MaineRocks from '../images/artwork/Memories/IMG_9257.jpg'
import Bavaria from '../images/artwork/Memories/Memory2/bavaria.jpg'
import BurialFlowers from '../images/artwork/Memories/Memory2/BurialFlowers.jpg'
import SchwaebGate from '../images/artwork/Memories/Memory2/DSC04202.jpg'
import Prague from '../images/artwork/Memories/Memory2/DSC04848.jpg'
import FlowersFromMarie from '../images/artwork/Memories/Memory2/FlowerFromMarie.jpg'
import Hampi from '../images/artwork/Memories/Memory2/hampi.jpg'
import Ozarks from '../images/artwork/Memories/Memory2/IMG_0141.jpg'
import Horse from '../images/artwork/Memories/Memory2/IMG_0175.jpg'
import Bourbon from '../images/artwork/Memories/Memory2/IMG_0404.jpg'
import RhinelandBear from '../images/artwork/Memories/Memory2/IMG_0452.jpg'
import RhinelandLeaves from '../images/artwork/Memories/Memory2/IMG_0461.jpg'
import Harper from '../images/artwork/Memories/Memory2/IMG_0483.jpg'
import Laramie from '../images/artwork/Memories/Memory2/IMG_0694.jpg'
import WyomingArrow from '../images/artwork/Memories/Memory2/IMG_0728.jpg'
import SmithGardens from '../images/artwork/Memories/Memory2/IMG_0821.jpg'
import Botanical from '../images/artwork/Memories/Memory2/IMG_1257.jpg'
import Iguana from '../images/artwork/Memories/Memory2/IMG_1353.jpg'
import Dehli from '../images/artwork/Memories/Memory2/IMG_1838.jpg'
import DehraPalace from '../images/artwork/Memories/Memory2/IMG_1941.jpg'
import DogMom from '../images/artwork/Memories/Memory2/IMG_1972.jpg'
import Goddess from '../images/artwork/Memories/Memory2/IMG_2180.jpg'
import Bugs from '../images/artwork/Memories/Memory2/IMG_2190.jpg'
import Lizard from '../images/artwork/Memories/Memory2/IMG_2195.jpg'
import Goat from '../images/artwork/Memories/Memory2/IMG_2208.jpg'
import Window from '../images/artwork/Memories/Memory2/IMG_2278.jpg'
import Hampi2 from '../images/artwork/Memories/Memory2/IMG_3029.jpg'
import DehraBlue from '../images/artwork/Memories/Memory2/IMG_3542.jpg'
import ArkGate from '../images/artwork/Memories/Memory2/IMG_6953.jpg'
import Mississippi from '../images/artwork/Memories/Memory2/IMG_7115.jpg'
import Mohren from '../images/artwork/Memories/Memory2/mohren.jpg'
import Mush from '../images/artwork/Memories/Memory2/mushroom.jpg'
import Shaved from '../images/artwork/Memories/Memory2/shaved.jpg'
import StKatelijne from '../images/artwork/Memories/Memory2/stkatelijne.jpg'


export const photos = [
    {
        src: Goddess,
        width: 3,
        height: 4,
        title: "Auroville, India, 2014"
      },
      {
        src: FlowersFromMarie,
        width: 3,
        height: 4,
        title: "Kansas City, 2020"
      },
      {
        src: One,
        width: 2,
        height: 1.5,
        title: "Ireland, 2012"
      },
    {
        src: MaineRocks,
        width: 4,
        height: 3,
        title: "Rockbridge, Maine, 2014"
      },
      {
        src: Bavaria,
        width: 4,
        height: 3,
        title: "Bavaria, Germany, 2010"
      },

      {
        src: DehraPalace,
        width: 4,
        height: 3,
        title: "Dehradun, India, 2014"
      },
      {
        src: StockbridgeTree,
        width: 3.5,
        height: 4,
        title: "Lenox, Massachusetts, 2014"
      },
      {
        src: SmithGardens,
        width: 3,
        height: 4,
        title: "Smith Botanical Gardens, North Hampton, Massachusetts, 2014"
      },
      {
        src: Two,
        width: 4,
        height: 3,
        title: "Berlin, Germany 2010"
      },
      {
        src: Caterpillar,
        width: 4,
        height: 3,
        title: "Charleston, South Carolina, 2014"
      },
      {
        src: RhinelandLeaves,
        width: 4,
        height: 3,
        title: "Rhineland, Missouri 2012"
      },
      {
        src: ThirtyEight,
        width: 4,
        height: 3,
        title: "Brooklyn, New York, 2014"
      },
      {
        src: PrettyConstruction,
        width: 4,
        height: 3,
        title: "Schwäbisch Hall, Germany, 2010"
      },
      {
        src: Mary,
        width: 3,
        height: 4,
        title: "Sagrada Familia, Barcelona, Spain, 2012"
      },
      {
        src: MosquitoNet,
        width: 4,
        height: 3,
        title: "Auroville, India, 2014"
      },

      {
        src: CyclePoster,
        width: 3,
        height: 4,
        title: "Dehradun, India 2014"
      },
      {
        src: BerlinDance,
        width: 4,
        height: 3,
        title: "Berlin, Germany 2010"
      },
      {
        src: Facebook,
        width: 4,
        height: 3,
        title: "Dehradun, India, 2014"
      },
      {
        src: Octopus,
        width: 4,
        height: 3,
        title: "Barcelona, Spain, 2012"
      },
      {
        src: TwentySix,
        width: 4,
        height: 3,
        title: "Great Smokey Mountains, Tennessee, 2014"
    },
      {
        src: LampShop,
        width: 4,
        height: 2.5,
        title: "Barcelona, Spain, 2012"

      },
      {
        src: Nine,
        width: 4,
        height: 3,
        title: "Ghent, Belgium, 2010"
      },
      {
        src: SchwaeBalloons,
        width: 4,
        height: 3,
        title: "Schwäbisch Hall, Germany, 2010"
      },
      {
        src: TwentyEight,
        width: 4,
        height: 3,
        title: "Coimbatore, India, 2014"
      },
      {
        src: ThirtyThree,
        width: 3,
        height:4,
        title: "Tony, Kansas City, 2015"
      },
      {
        src: Thirty,
        width: 4,
        height: 3,
        title: "Coorg, India, 2014"

      },
      {
        src: Shaved,
        width: 3,
        height: 4,
        title: "Kansas City, 2009"
      },
      {
        src: Botanical,
        width: 3,
        height: 4,
        title: "Berkshires, Massachusetts, 2014"
      },
      {
        src: WyomingArrow,
        width: 2.5,
        height: 4,
        title: "Laramie, Wyoming, 2012"
      },
      {
        src: BurialFlowers,
        width: 3,
        height: 4,
        title: "Kansas City, 2020"
      },
      {
        src: TwentySeven,
        width: 4,
        height: 3,
      title: "Dehradun, India, 2014"

      },
      {
        src: ThirtyOne,
        width: 4,
        height: 3,
        title: "New York City, 2015"
      },
      {
        src: Mohren,
        width: 4,
        height: 3,
        title: "Mohrenkopf pigs, Schwäbisch Hall, 2010"
      },
      {
        src: Bugs,
        width: 4,
        height: 3,
        title: "Auroville, India, 2014"
      },
      {
        src: DogMom,
        width: 4,
        height: 3,
        title: "Dehradun, India, 2014"
      },
      {
        src: ThirtyFour,
        width: 3,
        height: 4,
        title: "Mysore, India, 2014"

      },
      {
        src: Goat,
        width: 3,
        height: 4,
        title: "Auroville, India, 2014"
      },

      {
        src: Hampi2,
        width: 4,
        height: 3,
        title: "Hampi, India, 2014"
      },
      {
        src: Mississippi,
        width: 4.5,
        height: 3,
        title: "Mississippi, 2013"
      },
      {
        src: Laramie,
        width: 3,
        height: 4,
        title: "Laramie, Wyoming, 2012"
      },
      {
        src: ThirtyFive,
        width: 3,
        height: 4,
        title: "Northeast, Kansas City, 2015"
      },
      {
        src: AurovilleGreenGate,
        width: 4,
        height: 3,
        title: "Auroville, India, 2014"
      },
      {
        src: Prague,
        width: 4,
        height: 3,
        title: "Prague, Czech Republic, 2010"
      },
      {
        src: SchwaebGate,
        width: 3,
        height: 4,
        title: "Schwäbisch Hall, Germany 2010"
      },
      {
        src: ArkGate,
        width: 3,
        height: 4,
        title: "Jasper, Arkansas 2013"
      },
      {
        src: DehraBlue,
        width: 4,
        height: 3,
        title: "Dehradun, India, 2014"
      },

      {
        src: Window,
        width: 3,
        height: 4,
        title: "Auroville, India, 2014"
      },
      {
        src: Hampi,
        width: 4,
        height: 3,
        title: "Hampi, India, 2014"
      },
      {
        src: Ozarks,
        width: 3,
        height: 4,
        title: "Missouri Ozarks, 2012"
      },

      {
        src: Lizard,
        width: 4,
        height: 3,
        title: "Auroville, India, 2014"
      },
      {
        src: TwentyFour,
        width: 3,
        height: 4,
        title: "Julian in Stockbridge, Massachusetts, 2019"
      },
      {
        src: Horse,
        width: 3,
        height: 4,
        title: "West Plains, Missouri 2012"
      },
      {
        src: Harper,
        width: 3,
        height: 4,
        title: "Harper Watson, Rhineland, Missouri 2012"
      },
      {
        src: RhinelandBear,
        width: 4,
        height: 3,
        title: "Rhineland, Missouri 2012"
      },
      {
        src: Mush,
        width: 3,
        height: 4,
        title: "Great Smokey Mountains, Tennessee, 2014"
      },
      {
        src: ThirtySix,
        width: 1,
        height: 1,
        title: "Mushroom Material Cast, Kansas City 2016"
      },
      {
        src: Dehli,
        width: 4,
        height: 3,
        title: "Old Delhi, India, 2014"
      },
    //   {
    //     src: StKatelijne,
    //     width: 4,
    //     height: 3,
    //     title: "Sint Katelijne-Waver, Belgium 2012"
    //   },
    //   {
    //     src: Eight,
    //     width: 4,
    //     height: 3,
    //     title: "Hamburg, Germany 2010"
    //   },

    //   {
    //     src: ThirtyTwo,
    //     width: 3,
    //     height: 4,
    //     title: "Hampi, India 2014"

    //   },
    //   {
    //     src: Four,
    //     width: 3.3,
    //     height: 4,
    //     title: "Schwäbisch Hall, Germany, 2010"
    //   },
    //   {
    //     src: Fifteen,
    //     width: 2.5,
    //     height: 4,
    //     title: "Avignon, France, 2012"

    //   },

    //   {
    //     src: Leipzig,
    //     width: 4,
    //     height: 3,
    //     title: "Leipzig, Germany, 2010"
    //   },    
    //   {
    //     src: Iguana,
    //     width: 4,
    //     height: 3,
    //     title: "Columbia, Missouri 2012"
    //   },
    //   {
    //     src: ThirtySeven,
    //     width: 3,
    //     height: 4,
    //     title: "Northeast, Kansas City, 2016"
    //   },

    //   {
    //     src: Bourbon,
    //     width: 4.5,
    //     height: 3,
    //     title: "Bourbon, Missouri 2012"
    //   },
    //   {
    //     src: Fourteen,
    //     width: 4.25,
    //     height: 3,
    //     title: "St. Tropez, 2012"
    //   },
];